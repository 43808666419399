<template>
  <div class="content">
    <div class="deposit_box">
      <p style="padding: 20px 0;color: #000;">会员等级</p>
      <div class="content_box">
        <div v-for="(item, i) in goodsList" :key="item.id" class="box" :class="i == index ? 'active' : ''" @click="
          index = i;
        money = item.price;
        goods_id = item.id;
        ">
          <span style="font-size: 20px">{{ item.title }}</span><br />
          <span style="font-size: 14px">{{ item.price }}￥</span>
          <br />
          <span style="font-size: 12px;color:#d70101;">赠送{{ item.gold }}金币</span>
        </div>
      </div>
      <p style="font-size: 18px; padding: 20px 0;color: #000;">
        应付金额：<span style="color: #ff2d55; font-size: 30px; line-height: 30px">{{ money }}</span>
        元
      </p>
      <div>
        <div class="pay_btn" @click="goMoney">
          <img src="../../assets/zhifubao.png" alt="" />
          <span>立即充值</span>
        </div>
      </div>
      <p style="
          font-size: 13px;
          line-height: 14px;
          color: #505050;
          margin-top: 10px;
        ">
        已阅读并同意《<el-link type="primary" :underline="false" @click="$router.push('/user_pay')">用户支付协议</el-link>》
      </p>
    </div>

    <!-- 登录注册 -->
    <el-dialog :title="registerFlag ? '登录账号' : '注册账号'" :visible.sync="dialogVisible" width="600px" @closed="openInint"
      :close-on-click-modal="false">
      <el-form label-position="left" label-width="80px" :model="registerForm" ref="registerForm"
        style="width: 350px; margin: 0 auto" :rules="registerRules">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="registerForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="msg_code">
          <el-input v-model="registerForm.msg_code">
            <el-button slot="append" type="text" @click="sendcode" :disabled="!show">
              获取验证码
              <span v-show="!show" class="count">({{ count }}s)</span>
            </el-button>
          </el-input>
        </el-form-item>

        <el-button @click="Login" style="width: 350px">{{
          registerFlag ? "立即登录" : "立即注册"
        }}</el-button>
        <div>
          同意<el-button type="text" @click="goAgreement">用户协议</el-button>及<el-button type="text"
            @click="goConceal">隐私政策</el-button>
        </div>
      </el-form>
      <el-dialog width="400px" title="请完成下列验证后继续" :visible.sync="innerVisible" append-to-body v-if="innerVisible"
        :close-on-click-modal="false">
        <slide-verify v-loading="slideLoading" element-loading-spinner="el-icon-loading" element-loading-text="正在验证"
          class="slide" :l="42" :r="10" :w="340" :h="165" @success="onSuccess" @fail="onFail" @refresh="onRefresh"
          slider-text="按住左边按钮拖动完成上方拼图"></slide-verify>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { client, getFileNameUUID, dateFormat } from "@/util/update";
const TIME_COUNT = 60;
export default {
  data() {
    return {
      slideLoading: false,
      goodsList: [],
      active: "",
      index: 0,
      goods_id: 1,
      money: '',
      radio1: "1",
      dialogVisible: false,
      timer: null, //计时器
      code: "", //验证码
      count: "", //倒计时
      show: true, //控制按钮
      registerFlag: true,
      innerVisible: false,
      registerForm: {
        phone: "",
        msg_code: "",
      },
      registerRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "change" },
          { min: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        msg_code: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getGoodsList();
  },
  methods: {
    async getGoodsList() {
      const [err, res] = await this.$http.post("web/Order/goodsList", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        // this.$message.error("发生未知错误,请重新尝试");
        return;
      }
      this.money = res.goodsList[this.index].price
      this.goodsList = res.goodsList;
    },
    goMoney() {
      if (this.$store.state.login_token == "") {
        this.dialogVisible = true;
        this.registerFlag = true;
      } else {
        window.location.href =
          "http://api.tianqi.video/web/order/createOrder?token=" +
          this.$store.state.login_token +
          "&id=" +
          this.$store.state.user_id +
          "&goods_id=" +
          this.goods_id;
      }
    },
    openInint() {
      this.$refs.registerForm.resetFields();
    },
    // 登录
    async Login() {
      this.$refs.registerForm.validate(async (v, i) => {
        if (v) {
          const { registerForm } = this;
          const [err, res] = await this.$http.post("web/login/login", {
            ...registerForm,
          });
          if (err) {
            return;
          }
          this.$store.commit("CHANGE_LOGIN_TOKEN", res.login_token);
          this.$store.commit("CHANGE_NICK_NAME", res.nickname);
          this.$store.commit("CHANGE_USER_ID", res.id);
          this.$store.commit("CHANGE_AVATAR", res.avatar);
          this.$store.commit("CHANGE_VIP", res.vip);
          this.dialogVisible = false;
          localStorage.setItem("stateInfo", JSON.stringify(this.$store.state));
          this.$message({
            message: "登陆成功",
            type: "success",
          });
        }
      });
    },
    // 发送验证码
    sendcode() {
      this.$refs.registerForm.validateField("phone", async (error) => {
        if (!error) {
          this.innerVisible = true;
          //请求
        } else {
          return false;
        }
      });
    },
    goAgreement() {
      this.registerForm = {
        phone: "",
        msg_code: "",
      };
      this.dialogVisible = false;
      this.$router.push("/user_text");
    },
    goConceal() {
      this.registerForm = {
        phone: "",
        msg_code: "",
      };
      this.dialogVisible = false;
      this.$router.push("/conceal");
    },
    onSuccess() {
      // this.innerVisible = false
      this.slideLoading = true;
      setTimeout(async () => {
        setTimeout(() => {
          this.slideLoading = false;
          this.innerVisible = false;
        }, 2000);
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer); // 清除定时器
              this.timer = null;
            }
          }, 1000);
        }
        const [err, res] = await this.$http.post("web/tools/sendSMS", {
          phone: this.registerForm.phone,
        });
        if (err) {
          return;
        }
      }, 2000);
    },
    onFail() {
      this.msg = "";
    },
    onRefresh() {
      this.msg = "";
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  color: #fff;
  width: 100%;


  .deposit_box {
    margin: 0 auto;
    height: 400px;
    width: 1000px;
    padding: 0 48px 40px;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 12px;

    .content_box {
      display: flex;
      justify-content: space-around;

      
      .box {
        cursor: pointer;
        width: 211px;
        padding: 30px 0;
        border-radius: 4px;
        text-align: center;
        border: 1px solid #e4e0e0;
        color: #4b4b4b;
      }
      .active {
        color: #000;
        background-color: #fff;
        border: 1px solid #f3eaf1;
        font-weight: bold;
      }
    }

    .pay_btn {
      margin-top: 20px;
      background-color: #fe2c55;
      width: 442px;
      height: 60px;
      color: #fff;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: PingFangSC-Medium;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px;
      font-size: 16px;

      * {
        margin-left: 4px;
      }

      img {
        height: 18px;
      }
    }
  }

  .deposit_header {
    height: 70px;
    line-height: 70px;
    padding-left: 80px;
    font-size: 24px;
    font-weight: bolder;
  }
}
</style>